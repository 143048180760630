import React, { Component, Suspense, lazy } from "react";
import { homePage as data } from "../../../data/homePage/homePage";
import "react-circular-progressbar/dist/styles.css";
import Styles from "./homepage.module.css";
import "./homepage.css";
import { Swiper, SwiperSlide } from "swiper/react";
// import { Scrollbar } from "swiper/modules";

// import { Pagination, Scrollbar } from "swiper";

import "swiper/swiper-bundle.css";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay, EffectFade } from "swiper/modules";

import "swiper/css/navigation";
import "swiper/element/css/effect-fade";
// import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

// import CuttingEdge from "./cutting_edge/CuttingEdge";
// import IntegrateContainer from "./integrate_container/IntegrateContainer";
// import PlayField from "./play_field/PlayField";
// import FeatureaAndFunctinalities from "./features_functionalities/FeatureaAndFunctinalities";
// import BuildingIntelligence from "./building_intelligence/BuildingIntelligence";
// import EfficiencyWrapper from "./efficiency_wrapper/EfficiencyWrapper";
// import SmartSpaces from "./smart_spaces/SmartSpaces";
// import BlogsSection from "./blogs_section/BlogsSection";
// import OurExpertise from "./our_expertise/OurExpertise";
// import ManageIt from "./manage_It/ManageIt";
// import IntegrateDigitize from "./integrate_digitize/IntegrateDigitize";
// import CareerOption from "components/childcontainer/careers/CareersOption.js";

// Lazy imports
const CuttingEdge = lazy(() => import("./cutting_edge/CuttingEdge"));
const IntegrateContainer = lazy(() => import("./integrate_container/IntegrateContainer"));
const PlayField = lazy(() => import("./play_field/PlayField"));
const FeatureaAndFunctinalities = lazy(() => import("./features_functionalities/FeatureaAndFunctinalities"));
const BuildingIntelligence = lazy(() => import("./building_intelligence/BuildingIntelligence"));
const EfficiencyWrapper = lazy(() => import("./efficiency_wrapper/EfficiencyWrapper"));
const SmartSpaces = lazy(() => import("./smart_spaces/SmartSpaces"));
const ManageIt = lazy(() => import("./manage_It/ManageIt"));
const BlogsSection = lazy(() => import("./blogs_section/BlogsSection"));
const IntegrateDigitize = lazy(() => import("./integrate_digitize/IntegrateDigitize"));
const OurExpertise = lazy(() => import("./our_expertise/OurExpertise"));

class HomePage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			displayTitleNumber: 1,
			isMobile: window.innerWidth <= 767,
			isTab: window.innerWidth > 767 && window.innerWidth <= 1024,
			isSemi: window.innerWidth > 1024 && window.innerWidth <= 1366,
			isBuildingVisible: false,
			isCuttingEdgeVisible: false,
			isEffciencyWrapperVisible: false,
			isFeaturesVisible: false,
			isIntegrateContainerVisible: false,
			isIntegrateDigitizeVisible: false,
			isPlayFieldVisible: false,
			isSmartSpacesVisible: false,
			isSubscribeSection: false,
			isBlogsSectionVisible: false,
			isManageItVisible: false,
			isContactUsHomepageVisible: false,
			isExpertiseVisible: false,
			selectedTextIndex: 0,
		};
		//refs
		this.cutting_edge_cont = null;
		this.building_intelligence = null;
		this.manage_it = null;
		this.efficiency_wrapper = null;
		this.features_and_functionality = null;
		this.integrate_container = null;
		this.playfield = null;
		this.smart_spaces = null;
		this.our_expertise = null;
		this.blogs_section = null;
	}
	handleWindowSizeChange = () => {
		this.setState({
			isMobile: window.innerWidth <= 767,
			isTab: window.innerWidth > 767 && window.innerWidth <= 1024,
			isSemi: window.innerWidth > 1024 && window.innerWidth <= 1366,
		});
	};
	handleHeadingClick = (index) => {
		this.setState({ selectedImageIndex: index });
	};
	loadBGImg = (name) => {
		try {
			return require(`assets/homepage/${name}.webp`);
		} catch (e) {
			return require(`assets/homepage/header_banner_1.webp`);
		}
	};

	// window.onbeforeunload(){
	// 	window.scrollTo(0, 0);
	// }
	componentDidMount() {
		console.log("Mobile", this.state.isMobile);
		this.props.setHideFooter(true);
		this.handelSlider();
		// const rootMargin = this.state.isMobile ? "0% 0% -10% 0%" : "0% 0% -17% 0%";
		const rootMargin = this.state.isMobile ? "0% 0% 0% 0%" : "0% 0% -20% 0%";
		this.io = new window.IntersectionObserver(
			([entry]) => {
				if (entry.target === this.building_intelligence && entry.isIntersecting) {
					this.setState({ isBuildingVisible: true });
				}
				if (entry.target === this.manage_it && entry.isIntersecting) {
					this.setState({ isManageItVisible: true });
				}

				if (entry.target === this.cutting_edge_cont && entry.isIntersecting) {
					this.setState({ isCuttingEdgeVisible: true });
				}
				if (entry.target === this.efficiency_wrapper && entry.isIntersecting) {
					this.setState({ isEffciencyWrapperVisible: true });
				}
				if (entry.target === this.integrate_container && entry.isIntersecting) {
					this.setState({ isIntegrateContainerVisible: true });
				}
				if (entry.target === this.integrate_digitize && entry.isIntersecting) {
					this.setState({ isIntegrateDigitizeVisible: true });
				}

				if (entry.target === this.features_and_functionality && entry.isIntersecting) {
					this.setState({ isFeaturesVisible: true });
				}
				if (entry.target === this.playfield && entry.isIntersecting) {
					this.setState({ isPlayFieldVisible: true });
				}
				if (entry.target === this.our_expertise && entry.isIntersecting) {
					this.setState({ isExpertiseVisible: true });
				}

				if (entry.target === this.smart_spaces && entry.isIntersecting) {
					this.setState({ isSmartSpacesVisible: true });
				}

				if (entry.target === this.blogs_section && entry.isIntersecting) {
					this.setState({ isBlogsSectionVisible: true });
				}

				this.props.setHideFooter(false);
			},
			{ rootMargin }
		);
		this.io.observe(this.building_intelligence);
		this.io.observe(this.manage_it);
		this.io.observe(this.cutting_edge_cont);
		this.io.observe(this.efficiency_wrapper);
		this.io.observe(this.integrate_container);
		this.io.observe(this.integrate_digitize);

		this.io.observe(this.features_and_functionality);

		this.io.observe(this.playfield);
		this.io.observe(this.our_expertise);
		this.io.observe(this.smart_spaces);
		this.io.observe(this.blogs_section);
	}

	componentWillUnmount() {
		clearInterval(this.myInterval);
		this.props.setHideFooter(false);
		window.removeEventListener("resize", this.handleWindowSizeChange);
		this.io.disconnect();
	}

	handelSlider = () => {
		this.myInterval = setInterval(() => {
			if (this.state.displayTitleNumber === 5) {
				this.setState({ displayTitleNumber: 1 });
			} else {
				this.setState({
					displayTitleNumber: this.state.displayTitleNumber + 1,
				});
			}
		}, 7000);
	};

	loadBGImgMobile = (name) => {
		try {
			return require(`assets/homepage/homepage_mobile_banner/${name}.webp`);
		} catch (e) {
			return require(`assets/homepage/homepage_mobile_banner/banner_1.webp`);
		}
	};
	loadBGImgTab = (name) => {
		try {
			return require(`assets/homepage/homepage_mobile_banner/${name}.webp`);
		} catch (e) {
			return require(`assets/homepage/homepage_mobile_banner/banner_tab_1.webp`);
		}
	};
	initSwiper = () => {
		new Swiper(".swiper-container", {
			direction: "vertical",
			slidesPerView: "auto",
			freeMode: true,
			Scrollbar: {
				el: ".swiper-scrollbar",
			},
		});
	};
	handleSelectText = (index) => {
		this.setState({ selectedTextIndex: index });
	};
	render() {
		return (
			<main>
				<Helmet>
					<title>Sclera : Building intelligence and facility management tech solutions</title>
					<meta
						name="description"
						content="Sclera is a breakthrough building management solution engineered to revolutionize how businesses monitor and manage assets, workforce, and operations."
					/>
					<link rel="canonical" href="https://sclera.com/"></link>
					<link rel="image_src" href="https://sclera.com/thumb192.png" />

					{/* Facebook tags */}
					<meta property="og:type" content="website" />
					<meta property="og:title" content="Sclera : Building intelligence and facility management tech solutions" />
					<meta
						property="og:description"
						content="Sclera is a breakthrough building management solution engineered to revolutionize how businesses monitor and manage assets, workforce, and operations."
					/>
					<meta property="og:image" content="https://sclera.com/thumb192.png" />
					<meta property="og:image:width" content="1200" />
					<meta property="og:image:height" content="630" />
					<meta property="og:url" content="https://sclera.com/" />
					{/* End Facebook tags */}
					{/* Twitter tags */}
					<meta name="twitter:card" content="summary_large_image" />
					<meta name="twitter:title" content="Sclera : Building intelligence and facility management tech solutions" />
					<meta
						name="twitter:description"
						content="Sclera is a breakthrough building management solution engineered to revolutionize how businesses monitor and manage assets, workforce, and operations."
					/>
					<meta name="twitter:image" content="https://sclera.com/thumb192.png" />
					{/* End Twitter tags */}
				</Helmet>

				<section>
					<Suspense fallback={<div></div>}>
						<div className={Styles.slider_mainWrapper}>
							<div className={`slider_subWrapper ${Styles.slider_subWrapper}`}>
								<Swiper
									loop={true}
									spaceBetween={20}
									centeredSlides={true}
									pagination={{
										clickable: true,
									}}
									modules={[Pagination, Autoplay, EffectFade]}
									autoplay={{
										delay: 10000,
										disableOnInteraction: false,
									}}
									className="mySwiper"
								>
									{data.banner_content?.map((item, index) => (
										<SwiperSlide key={index}>
											<div className={Styles.slide_img_wrapper}>
												{this.state.isMobile ? (
													<img
														fetchpriority={item.priority}
														src={this.loadBGImgMobile(item.img_mobile)}
														alt={item.title}
														preload
														// loading="lazy"
													/>
												) : this.state.isTab ? (
													<img
														src={this.loadBGImgTab(item.image_tab)}
														alt={item.title}
														width="100%"
														height="100%"
														fetchpriority={item.priority}
														loading="lazy"
													/>
												) : (
													<img
														fetchpriority={item.priority}
														src={this.loadBGImg(item.img)}
														alt={item.title}
														preload
														loading="lazy"
													/>
												)}
											</div>
											{item.sub_title === "" ? null:<div className={Styles.displayTitle}>
												<h1 className={Styles.slider_title}>
													{item.title} <span>{item.sub_title}</span>
												</h1>
												<div>
													<p className={Styles.slider_titleInfo}>{item.para}</p>
												</div>
											</div>}
											
										</SwiperSlide>
									))}{" "}
								</Swiper>{" "}
							</div>
						</div>
					</Suspense>
				</section>

				<section
					ref={(section) => {
						this.building_intelligence = section;
					}}
				>
					<Suspense fallback={<div></div>}>{this.state.isBuildingVisible ? <BuildingIntelligence /> : null}</Suspense>
				</section>
				<section
					ref={(section) => {
						this.manage_it = section;
					}}
				>
					<Suspense fallback={<div></div>}>{this.state.isManageItVisible ? <ManageIt /> : null}</Suspense>
				</section>

				<section
					ref={(section) => {
						this.cutting_edge_cont = section;
					}}
				>
					<div className={`work-smarter-swipper ${Styles.work_smarter}`}>
						<div>
							{/* <Suspense fallback={<div></div>}>
								{this.state.isCuttingEdgeVisible ? */}
							<CuttingEdge data={data?.role?.role_options} />
							{/* : null}
							</Suspense> */}
						</div>
					</div>
				</section>
				<section
					ref={(section) => {
						this.efficiency_wrapper = section;
					}}
				>
					<Suspense fallback={<div></div>}>{this.state.isEffciencyWrapperVisible ? <EfficiencyWrapper /> : null}</Suspense>
				</section>
				<section
					ref={(section) => {
						this.integrate_container = section;
					}}
				>
					{/* <Suspense fallback={<div></div>}>{this.state.isIntegrateContainerVisible ?  */}
					<IntegrateContainer />
					{/* : null}</Suspense> */}
				</section>
				<section
					ref={(section) => {
						this.integrate_digitize = section;
					}}
				>
					<Suspense fallback={<div></div>}>{this.state.isIntegrateDigitizeVisible ? <IntegrateDigitize /> : null}</Suspense>
				</section>

				<section
					ref={(section) => {
						this.features_and_functionality = section;
					}}
				>
					<Suspense fallback={<div></div>}>{this.state.isFeaturesVisible ? <FeatureaAndFunctinalities /> : null}</Suspense>
				</section>
				<section
					ref={(section) => {
						this.playfield = section;
					}}
				>
					<Suspense fallback={<div></div>}>{this.state.isPlayFieldVisible ? <PlayField /> : null}</Suspense>
				</section>
				<section
					ref={(section) => {
						this.our_expertise = section;
					}}
				>
					<Suspense fallback={<div></div>}>{this.state.isExpertiseVisible ? <OurExpertise /> : null}</Suspense>
				</section>
				<section
					ref={(section) => {
						this.smart_spaces = section;
					}}
				>
					{/* <Suspense fallback={<div></div>}>{this.state.isSmartSpacesVisible ?  */}
					<SmartSpaces />
					{/* : null}</Suspense> */}
				</section>

				<section
					ref={(section) => {
						this.blogs_section = section;
					}}
				>
					{/* <Suspense fallback={<div></div>}> {this.state.isBlogsSectionVisible ? */}
					<BlogsSection />
					{/* : null}</Suspense> */}
				</section>
			</main>
		);
	}
}

export default HomePage;
