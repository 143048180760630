import React, { Component } from "react";
import { Link } from "react-router-dom";
import Styles from "./header.module.css";
import { Turn as Hamburger } from "hamburger-react";
import { motion } from "framer-motion";
import { ReactComponent as ScleraLogo } from "assets/homepage/sclera_logo_footer.svg";
import { withRouter } from "helper/withRouter.js";
import { ReactComponent as PlusIcon } from "assets/homepage/addicon.svg";
import { ReactComponent as CloseIcon } from "assets/homepage/close_icon.svg";

class Header extends Component {
	state = {
		pageHovered: "",
		page: "",
		selectedPage: "",
	};

	constructor(props) {
		super(props);
		this.state = {
			isHover1: false,
			isHover2: false,
			navbarOpen: false,
			showNavbar: false,
			isActive: null,
		};
	}

	handleClick = (section) => {
		this.setState((prevState) => ({
			isActive: prevState.isActive === section ? null : section,
		}));
	};

	handleCloseClick = () => {
		this.setState({ showNavbar: false });
	};
	handleCloseIconClick = (e) => {
		e.stopPropagation();
		this.setState({
			isActive: null,
		});
	};

	componentDidMount() {
		let currentUrl = window.location.href;
		let urlParts = currentUrl.split("/");
		let page = urlParts[urlParts.length - 1];
		let selectedPage = urlParts[4];
		this.setState({
			page,
			selectedPage,
		});
	}

	componentDidUpdate = (prevProps) => {
		let currentUrl = window.location.href;
		let urlParts = currentUrl.split("/");
		let page = urlParts[urlParts.length - 1];
		let selectedPage = urlParts[urlParts.length - 2];

		if (prevProps !== this.props) {
			this.setState({
				page,
				selectedPage,
			});
		}
	};
	handleMouseClick = (option) => {
		if (option === "blogs") {
			this.setState({ pageHovered: false });
		} else {
			this.setState({
				pageHovered: option,
				active: true,
			});
		}
	};
	handleCloseIcon = (e) => {
		e.stopPropagation();
		this.setState({
			active: false,
			pageHovered: "",
		});
	};
	ScrollToTop = () => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	};
	render() {
		const { showNavbar } = this.state;

		return (
			<header
				className={
					this.state.page === "contact-us" ||
					this.props.section === "blog-details" ||
					this.state.page === "privacy-policy" ||
					this.state.selectedPage === "Careers" ||
					this.props.notFound
						? Styles.sclera_header_grey_container
						: Styles.sclera_header_container
				}
			>
				<div className={Styles.desktop_header_wrapper}>
					<Link to="/">
						<ScleraLogo
							className={
								this.state.page === "contact-us" ||
								this.props.section === "blog-details" ||
								this.state.page === "privacy-policy" ||
								this.state.selectedPage === "Careers" ||
								this.props.notFound
									? Styles.scleralogo_black
									: Styles.scleralogo
							}
							alt="logo"
							onClick={this.ScrollToTop()}
						/>
					</Link>

					<nav className={Styles.sclera_options_wrapper}>
						<ul className={Styles.header_unordered_list}>
							{/* <li>
								<div className={Styles.sclera_dropdown} onClick={() => this.handleMouseClick("solutions")}>
									<p
										className={
											this.state.selectedPage === "solutions"
												? Styles.activepageTitle
												: this.state.page === "contact-us" ||
												  this.props.section === "blog-details" ||
												  this.state.page === "privacy-policy" ||
												  this.props.notFound
												? Styles.dropdown_black_title
												: Styles.dropdown_title
										}
									>
										{" "}
										Solutions
									</p>
									&nbsp;
									{this.state.pageHovered === "solutions" && (
										<div className={Styles.sclera_dropdown_content}>
											<div className={Styles.solutions_header}>
												<div className={Styles.dropdwon_main_heading}>Solutions</div>
												<CloseIcon
													className={Styles.close_icon}
													onClick={(e) => {
														this.handleCloseIcon(e);
													}}
												/>
											</div>
											<div className={Styles.dropdown_options}>
												<div className={Styles.dropdwon_option1}>
													<Link
														className={Styles.hover_option_section1}
														to="/solutions/digital-transformation"
														style={{ textDecoration: "none" }}
													>
														<span className="fontcolor"> Digital Transformation </span>
														&nbsp;
													</Link>
													<Link
														className={Styles.hover_option_section}
														to="/solutions/building-systems-integration"
														style={{ textDecoration: "none" }}
													>
														<span className="fontcolor">Building Systems Integration</span>
														&nbsp;
													</Link>
												</div>
												<div className={Styles.dropdwon_option1}>
													<Link
														className={Styles.hover_option_section}
														to="/solutions/smart-operations-center"
														style={{ textDecoration: "none" }}
													>
														<span className="fontcolor"> Smart Operations Center</span>
														&nbsp;
													</Link>
													<Link
														to="/solutions/single-pane-of-glass"
														className={Styles.hover_option_section_last}
														style={{ textDecoration: "none" }}
													>
														<span className="fontcolor">Single Pane of Glass</span>
														&nbsp;
													</Link>
												</div>
											</div>
										</div>
									)}
								</div>
							</li> */}
							<li>
								<div className={Styles.sclera_dropdown} onClick={() => this.handleMouseClick("products")}>
									<p
										className={
											this.state.selectedPage === "features"
												? Styles.activepageTitle
												: this.state.page === "contact-us" ||
												  this.props.section === "blog-details" ||
												  this.state.page === "privacy-policy" ||
												  this.props.notFound
												? Styles.dropdown_black_title
												: Styles.dropdown_title
										}
									>
										{" "}
										{/* Products */}
										Features
									</p>
									&nbsp;
									{this.state.pageHovered === "products" && (
										<div className={Styles.sclera_dropdown_content}>
											<div className={Styles.solutions_header}>
												<div className={Styles.dropdwon_main_heading}>Features</div>
												<CloseIcon
													className={Styles.close_icon}
													onClick={(e) => {
														this.handleCloseIcon(e);
													}}
												/>
											</div>
											<div className={Styles.dropdown_options}>
												<div className={Styles.dropdwon_option1}>
													<Link
														className={Styles.hover_option_section1}
														to="/features/asset-digitization"
														style={{ textDecoration: "none" }}
														onClick={(e) => {
															this.handleCloseIcon(e);
														}}
													>
														<span className="fontcolor">Asset Digitization</span>
														&nbsp;
													</Link>
													<Link
														className={Styles.hover_option_section}
														to="/features/inspection-digitization"
														style={{ textDecoration: "none" }}
														onClick={(e) => {
															this.handleCloseIcon(e);
														}}
													>
														<span className="fontcolor">Inspection Digitization</span>
														&nbsp;
													</Link>
													<Link
														className={Styles.hover_option_section}
														to="/features/network-monitoring"
														style={{ textDecoration: "none" }}
														onClick={(e) => {
															this.handleCloseIcon(e);
														}}
													>
														<span className="fontcolor">Remote Access and Asset Monitoring</span>
														&nbsp;
													</Link>
												</div>{" "}
												<div className={Styles.dropdwon_option1}>
													<Link
														className={Styles.hover_option_section}
														to="/features/sensors"
														style={{ textDecoration: "none" }}
														onClick={(e) => {
															this.handleCloseIcon(e);
														}}
													>
														<span className="fontcolor">BMS and IoT Monitoring</span>
														&nbsp;
													</Link>
													<Link
														className={Styles.hover_option_section}
														to="/features/integrated-os"
														style={{ textDecoration: "none" }}
														onClick={(e) => {
															this.handleCloseIcon(e);
														}}
													>
														<span className="fontcolor">Data Platform Integration</span>
														&nbsp;
													</Link>
												</div>{" "}
											</div>
										</div>
									)}
								</div>
							</li>
							<li>
								<div className={Styles.sclera_dropdown} onClick={() => this.handleMouseClick("industries")}>
									<p
										className={
											this.state.selectedPage === "industries"
												? Styles.activepageTitle
												: this.state.page === "contact-us" ||
												  this.props.section === "blog-details" ||
												  this.state.page === "privacy-policy" ||
												  this.props.notFound
												? Styles.dropdown_black_title
												: Styles.dropdown_title
										}
									>
										{" "}
										Industries
									</p>
									&nbsp;
									{this.state.pageHovered === "industries" && (
										<div className={Styles.sclera_dropdown_content}>
											<div className={Styles.solutions_header}>
												<div className={Styles.dropdwon_main_heading}>Industries</div>
												<CloseIcon
													className={Styles.close_icon}
													onClick={(e) => {
														this.handleCloseIcon(e);
													}}
												/>
											</div>
											<div className={Styles.dropdown_options}>
												<div className={Styles.dropdwon_option1}>
													<Link
														className={Styles.hover_option_section1}
														to="/industries/commercial-workspaces"
														style={{ textDecoration: "none" }}
														onClick={(e) => {
															this.handleCloseIcon(e);
														}}
													>
														<span className="fontcolor">Commercial Workspaces</span>
														&nbsp;
													</Link>
													<Link
														className={Styles.hover_option_section}
														to="/industries/hospitality"
														style={{ textDecoration: "none" }}
														onClick={(e) => {
															this.handleCloseIcon(e);
														}}
													>
														<span className="fontcolor">Hospitality</span>
														&nbsp;
													</Link>
												</div>

												<div className={Styles.dropdwon_option1}>
													<Link
														className={Styles.hover_option_section}
														to="/industries/retail-chains"
														style={{ textDecoration: "none" }}
														onClick={(e) => {
															this.handleCloseIcon(e);
														}}
													>
														<span className="fontcolor">Retail Chains</span>
														&nbsp;
													</Link>{" "}
													<Link
														className={Styles.hover_option_section_last}
														to="/industries/restaurant-chains"
														style={{ textDecoration: "none" }}
														onClick={(e) => {
															this.handleCloseIcon(e);
														}}
													>
														<span className="fontcolor"> Restaurant Chains</span>
														&nbsp;
													</Link>
												</div>
											</div>
										</div>
									)}
								</div>
							</li>
							<li>
								<div className={Styles.sclera_dropdown}>
									<div className="sclera-dropbtn" onClick={() => this.handleMouseClick("blogs")}>
										<Link to="/blogs/home">
											<p
												className={
													this.state.selectedPage === "blogs" || this.props.section === "blog-details"
														? Styles.activepageTitle_blogs
														: this.state.page === "contact-us" ||
														  this.state.page === "privacy-policy" ||
														  this.props.notFound
														? Styles.dropdown_black_title_blogs
														: Styles.dropdown_title_blogs
												}
											>
												Blogs
											</p>
										</Link>
									</div>
								</div>
							</li>
							<li>
								<div className={Styles.sclera_dropdown}>
									<div className="sclera-dropbtn" onClick={() => this.handleMouseClick("careers")}>
										<Link to="/careers/home">
											<p
												className={
													this.state.selectedPage === "careers"
														? Styles.activepageTitle_blogs
														: this.state.page === "contact-us" ||
														  this.state.page === "privacy-policy" ||
														  this.props.notFound
														? Styles.dropdown_black_title_blogs
														: Styles.dropdown_title_blogs
												}
											>
												Careers
											</p>
										</Link>
									</div>
								</div>
							</li>

							<li>
								<Link to="/contact-us">
									<div className={Styles.header_contact_btn}>Contact Us</div>
								</Link>
							</li>
						</ul>
					</nav>
				</div>
				{/* mobile and tab */}
				<div className={`${Styles.tab_n_mobile_header}  ${showNavbar ? Styles.openHeader : Styles.closeHeader}`}>
					<div className={`${Styles.hamburger_contents_wrapper} ${showNavbar ? Styles.open : Styles.close}`}>
						<div className={Styles.header_card_wrapper}>
							<nav className={Styles.header_cards}>
								<ul>
									{/* <li>
										{" "}
										<div
											onClick={() => {
												this.handleClick("Solutions");
											}}
											className={Styles.header_card}
										>
											<div className={Styles.header_card_heading}>Solutions</div>
											<div>
												<PlusIcon
													alt="downarrow"
													className={
														this.state.isActive === "Solutions"
															? Styles.header_downarrow_open
															: Styles.header_downarrow
													}
												/>
											</div>
										</div>
										{this.state.isActive === "Solutions" && (
											<motion.div
												style={{ overflow: "hidden" }}
												initial={{
													opacity: 1,
													height: "0",
												}}
												animate={{
													opacity: 1,
													height: "12rem",
												}}
												transition={{
													duration: 0.8,
												}}
												className={Styles.header_card_dropdown}
											>
												<Link
													to="/solutions/digital-transformation"
													style={{ cursor: "pointer", textDecoration: "none" }}
													onClick={this.handleLinkClick}
												>
													{" "}
													<p className={Styles.header_card_content} onClick={this.handleCloseClick}>
														Digital Transformation
													</p>
												</Link>
												<Link
													to="/solutions/building-systems-integration"
													style={{ cursor: "pointer", textDecoration: "none" }}
													onClick={this.handleLinkClick}
												>
													{" "}
													<p className={Styles.header_card_content} onClick={this.handleCloseClick}>
														Building Systems Integration
													</p>
												</Link>
												<Link
													to="/solutions/smart-operations-center"
													style={{ cursor: "pointer", textDecoration: "none" }}
													onClick={this.handleLinkClick}
												>
													{" "}
													<p className={Styles.header_card_content} onClick={this.handleCloseClick}>
														Smart Operations Center
													</p>
												</Link>
												<Link
													to="/solutions/single-pane-of-glass"
													style={{ cursor: "pointer", textDecoration: "none" }}
													onClick={this.handleLinkClick}
												>
													{" "}
													<p className={Styles.header_card_content} onClick={this.handleCloseClick}>
														Single Pane of Glass
													</p>
												</Link>
											</motion.div>
										)}
									</li> */}
									<li>
										<div onClick={() => this.handleClick("Products")} className={Styles.header_card}>
											<div className={Styles.header_card_heading}>
												{/* Products */}
												Features
											</div>
											<div>
												<PlusIcon
													alt="downarrow"
													className={
														this.state.isActive === "Products"
															? Styles.header_downarrow_open
															: Styles.header_downarrow
													}
													// onClick={this.handleCloseIconClick}
												/>
											</div>
										</div>
										{this.state.isActive === "Products" && (
											<motion.div
												style={{ overflow: "hidden" }}
												initial={{
													opacity: 1,
													height: "0",
												}}
												animate={{
													opacity: 1,
													height: "15rem",
												}}
												transition={{
													duration: 0.8,
												}}
												exit={{ opacity: 0, height: "0" }}
												className={Styles.header_card_dropdown}
											>
												<Link
													to="/features/asset-digitization"
													style={{ cursor: "pointer", textDecoration: "none" }}
													onClick={this.handleLinkClick}
												>
													{" "}
													<p className={Styles.header_card_content} onClick={this.handleCloseClick}>
														Asset Digitization
													</p>
												</Link>

												<Link
													to="/features/inspection-digitization"
													style={{ cursor: "pointer", textDecoration: "none" }}
													onClick={this.handleLinkClick}
												>
													{" "}
													<p className={Styles.header_card_content} onClick={this.handleCloseClick}>
														Inspection Digitization
													</p>
												</Link>
												<Link
													to="/features/network-monitoring"
													style={{ cursor: "pointer", textDecoration: "none" }}
													onClick={this.handleLinkClick}
												>
													{" "}
													<p className={Styles.header_card_content} onClick={this.handleCloseClick}>
														Remote Access and Asset Monitoring
													</p>
												</Link>

												<Link
													to="/features/sensors"
													style={{ cursor: "pointer", textDecoration: "none" }}
													onClick={this.handleLinkClick}
												>
													{" "}
													<p className={Styles.header_card_content} onClick={this.handleCloseClick}>
														{" "}
														BMS and IoT Monitoring
													</p>
												</Link>
												<Link
													to="/features/integrated-os"
													style={{ cursor: "pointer", textDecoration: "none" }}
													onClick={this.handleLinkClick}
												>
													{" "}
													<p className={Styles.header_card_content} onClick={this.handleCloseClick}>
														Data Platform Integration
													</p>
												</Link>
											</motion.div>
										)}
									</li>
									<li>
										{" "}
										<div onClick={() => this.handleClick("Industries")} className={Styles.header_card}>
											<div className={Styles.header_card_heading}>Industries</div>
											<div>
												<PlusIcon
													alt="downarrow"
													className={
														this.state.isActive === "Industries"
															? Styles.header_downarrow_open
															: Styles.header_downarrow
													}
													// onClick={this.handleCloseIconClick}
												/>
											</div>
										</div>
										{this.state.isActive === "Industries" && (
											<motion.div
												style={{ overflow: "hidden" }}
												initial={{
													opacity: 1,
													height: "0",
												}}
												animate={{
													opacity: 1,
													height: "12rem",
												}}
												transition={{
													duration: 0.8,
												}}
												exit={{ opacity: 0, height: "0" }}
												className={Styles.header_card_dropdown}
											>
												<Link
													to="/industries/commercial-workspaces"
													style={{ cursor: "pointer", textDecoration: "none" }}
													onClick={this.handleLinkClick}
												>
													{" "}
													<p className={Styles.header_card_content} onClick={this.handleCloseClick}>
														Commercial Workspaces
													</p>
												</Link>
												<Link
													to="/industries/hospitality"
													style={{ cursor: "pointer", textDecoration: "none" }}
													onClick={this.handleLinkClick}
												>
													{" "}
													<p className={Styles.header_card_content} onClick={this.handleCloseClick}>
														Hospitality
													</p>
												</Link>
												<Link
													to="/industries/retail-chains"
													style={{ cursor: "pointer", textDecoration: "none" }}
													onClick={this.handleLinkClick}
												>
													{" "}
													<p className={Styles.header_card_content} onClick={this.handleCloseClick}>
														Retail Chains{" "}
													</p>
												</Link>
												<Link
													to="/industries/restaurant-chains"
													style={{ cursor: "pointer", textDecoration: "none" }}
													onClick={this.handleLinkClick}
												>
													{" "}
													<p className={Styles.header_card_content} onClick={this.handleCloseClick}>
														Restaurant Chains
													</p>
												</Link>
											</motion.div>
										)}
									</li>
									<li>
										{" "}
										<div className={Styles.header_card}>
											<Link
												to="/blogs/home"
												style={{ cursor: "pointer", textDecoration: "none" }}
												onClick={this.handleLinkClick}
											>
												{" "}
												<p className={Styles.header_card_heading} onClick={this.handleCloseClick}>
													Blogs
												</p>
											</Link>
										</div>
									</li>
									<li>
										{" "}
										<div className={Styles.header_card}>
											<Link
												to="/careers/home"
												style={{ cursor: "pointer", textDecoration: "none" }}
												onClick={this.handleLinkClick}
											>
												{" "}
												<p className={Styles.header_card_heading} onClick={this.handleCloseClick}>
													Careers{" "}
												</p>
											</Link>
										</div>
									</li>
								</ul>
							</nav>
							<div className={Styles.menu_header_btn}>
								<Link to="/contact-us">
									<div onClick={this.handleCloseClick} className={Styles.hamburger_contact_btn}>
										<button className={Styles.header_contact_btn}>CONTACT US</button>
									</div>
								</Link>
							</div>
						</div>
					</div>
					<>
						<div
							className={
								this.state.page === "contact-us" ||
								this.props.section === "blog-details" ||
								this.props.notFound ||
								this.state.page === "privacy-policy"
									? Styles.mobile_grey_container
									: Styles.mobile_header_container
							}
						>
							<div className={Styles.header_wrapper}>
								<Link to="/">
									<div className={Styles.header_logo_wrapper}>
										<ScleraLogo
											className={
												this.state.page === "contact-us" ||
												this.props.section === "blog-details" ||
												this.props.notFound ||
												this.state.page === "privacy-policy"
													? Styles.mobile_header_logo
													: Styles.header_logo
											}
											alt="logo"
										/>
									</div>
								</Link>

								<div className="hamburger-wrap">
									<Hamburger
										size={25}
										duration={0.5}
										color={
											this.state.page === "contact-us" ||
											this.props.section === "blog-details" ||
											this.props.notFound ||
											this.state.page === "privacy-policy"
												? "#00604d"
												: "#00604d"
										}
										easing="ease-in"
										toggled={this.state.showNavbar}
										onToggle={(toggled) => {
											this.setState({
												showNavbar: toggled,
											});
										}}
										label="header-menu"
									/>
								</div>
							</div>
						</div>
					</>
				</div>
			</header>
		);
	}
}

export default withRouter(Header);
