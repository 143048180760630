import React from "react";
import "./App.css";
import { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/childcontainer/header/Header";
import HomePage from "./components/childcontainer/homepage/HomePage";
// import Footer from "components/childcontainer/footer/Footer";
import { Suspense, lazy } from "react";
import { HelmetProvider } from "react-helmet-async";

// const HomePage = lazy(() => import("./components/childcontainer/homepage/HomePage"));
const Industries = lazy(() => import("./components/childcontainer/industries/Industries"));
const Contact = lazy(() => import("./components/childcontainer/contact/Contact"));
const Products = lazy(() => import("./components/childcontainer/products/Products"));
const Solutions = lazy(() => import("./components/childcontainer/solutions/Solutions"));
const Blogs = lazy(() => import("./components/childcontainer/blogs_container/BlogsContainer"));
const NotFoundPage = lazy(() => import("components/childcontainer/not_found/NotFound"));
const PrivacyPolicy = lazy(() => import("components/childcontainer/privacy_policy/PrivacyPolicy"));
const Footer = lazy(() => import("components/childcontainer/footer/Footer"));
const Careers = lazy(() => import("components/childcontainer/careers_container/CareersContainer"));
const TermsAndConditions = lazy(() => import("components/childcontainer/terms_and_conditions/TermsAndConditions"));
const SecurityPractices = lazy(() => import("components/childcontainer/security_practices/SecurityPractices"));
function App() {
	const [hideFooter, setHideFooter] = useState(false);
	const [notFound, setnotFound] = useState(false);

	let currentUrl = window.location.href;
	let urlParts = currentUrl.split("/");
	let page = urlParts[urlParts.length - 1];
	const helmetContext = {};
	return (
		<HelmetProvider context={helmetContext}>
			<div className="container">
				<React.Suspense fallback={<div></div>}>
					<BrowserRouter>
						{page !== "blogs" ? <Header notFound={notFound} /> : null}
						<Routes>
							<Route
								exact
								path="/"
								element={
									<Suspense fallback={<div></div>}>
										<HomePage setHideFooter={setHideFooter} />
									</Suspense>
								}
							></Route>
							<Route
								path="/solutions/:type"
								element={
									<Suspense fallback={<div></div>}>
										<Solutions />{" "}
									</Suspense>
								}
							></Route>
							<Route
								path="/features/:type"
								element={
									<Suspense fallback={<div></div>}>
										<Products />{" "}
									</Suspense>
								}
							></Route>
							<Route
								path="/industries/:type"
								element={
									<Suspense fallback={<div></div>}>
										<Industries />{" "}
									</Suspense>
								}
							></Route>
							<Route
								path="/blogs/:type"
								element={
									<Suspense fallback={<div></div>}>
										<Blogs />{" "}
									</Suspense>
								}
							></Route>
							<Route
								path="/careers/:type"
								element={
									<Suspense fallback={<div></div>}>
										<Careers />{" "}
									</Suspense>
								}
							></Route>
							<Route
								path="/contact-us"
								element={
									<Suspense fallback={<div></div>}>
										<Contact />{" "}
									</Suspense>
								}
							></Route>
							<Route
								path="*"
								exclude="/sitemap.xml"
								element={
									<Suspense fallback={<div></div>}>
										<NotFoundPage setnotFound={setnotFound} />{" "}
									</Suspense>
								}
							/>
							<Route
								path="/privacy-policy"
								element={
									<Suspense fallback={<div></div>}>
										<PrivacyPolicy />{" "}
									</Suspense>
								}
							></Route>
							<Route
								path="/terms-and-conditions"
								element={
									<Suspense fallback={<div></div>}>
										<TermsAndConditions />{" "}
									</Suspense>
								}
							></Route>
							<Route
								path="/security-practices"
								element={
									<Suspense fallback={<div></div>}>
										<SecurityPractices />{" "}
									</Suspense>
								}
							></Route>
						</Routes>
						<Suspense fallback={<div></div>}>
							<Footer hideFooter={hideFooter} />
						</Suspense>
					</BrowserRouter>
				</React.Suspense>
			</div>
		</HelmetProvider>
	);
}

export default App;
